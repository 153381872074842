<template>
    <div
        class="alert-check"
        @click="$emit('callback')">
        <action-button
            v-if="checkIfAlertIsRefreshing"
            size="14"
            icon=""
            :loading="true" />
        <icon
            v-else
            name="refresh"
            size="14" />
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import ActionButton from '@/components/globals/ActionButton';
import { mapState } from 'vuex';

export default {
    components: {
        Icon,
        ActionButton
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState({
            refreshingAlerts: (state) => state.monitoring.refreshingAlerts,
        }),
        checkIfAlertIsRefreshing() {
            return this.refreshingAlerts.findIndex(refreshing => refreshing.dealerId == this.item.dealer_id && refreshing.alertTypeId == this.item.alert_type_id) > -1;
        }
    }
};
</script>

<style lang="scss" scoped>
.alert-check {
    cursor: pointer;
}
</style>